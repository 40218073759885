<template>
  <div class="container is-fluid">
    <page-header :entity="$EntitiesName.Import">
      <h1 class="title">
        Import des fiches affaire
      </h1>
      <template #actions>
        <button cy-data="cy-import-button" class="button is-warning" @click="importSheets">Importer</button>
      </template>
    </page-header>

    <div class="notification is-success" v-if="importSuccess">
      <button class="delete" @click="importSuccess = false"></button>
      L'import s'est déroulé avec succès !
    </div>

    <div class="block has-background-warning-light error-message" v-if="importErrors">
      <p class="title is-6">Les lignes suivantes n'ont pas pu être importées :</p>
      <table class="table errors-table has-background-warning-light" style="width: 100%">
        <thead>
          <tr>
            <th class="has-text-left">Ligne</th>
            <th class="has-text-left">Raison</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(reason, line) in notImported" :key="'ni_' + line">
            <td>{{ Number(line) + 1 }}</td>
            <td>{{ reason }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="block has-background-warning-light error-message"
      v-if="hasError"
    >
      <p class="title is-6">
        Les lignes suivantes contiennent des erreurs :
      </p>
      <table
        class="table errors-table has-background-warning-light"
        style="width: 100%"
      >
        <thead>
        <tr>
          <th class="has-text-left">Ligne</th>
          <th class="has-text-left">Raison</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(error, line) in lineErrors" :key="'err_' + line">
          <td>{{ Number(error.row) + 1 }}</td>
          <td>{{ error.message }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <OrdersImport cy-data="cy-sheet-import" :columns="handsonColumns" v-model="importData" @valid="valid=$event" @errors="showErrors" />
  </div>
</template>
<script>
import axios from 'axios';
import OrdersImport from './Orders.vue'
export default {
  name: 'sap_import',
  components: { OrdersImport },
  data: function() {
    return {
      notImported: {},
      importSuccess: false,
      importData: [],
      valid: true,
      lineErrors: [],
      hasError: false,
      handsonColumns: [
        {
          label: 'Nom fiche affaire',
          key: 'name',
          required: true,
        },
        {
          label: 'Projet',
          key: 'project',
          required: true
        },
        {
          label: 'Modèle de fiche',
          key: 'sheetTemplate',
        },
        {
          label: 'Responsable',
          key: 'owner',
          required: true
        },
        {
          label: 'Sous-projet OSCAR',
          key: 'oscarSubProject',
        },
        {
          label: 'Statut de l\'affaire',
          key: 'status',
          required: true
        },
        {
          label: 'Jalon',
          key: 'milestone',
        },

        {
          label: 'Date de début',
          key: 'startDate',
        },
        {
          label: 'Date de fin',
          key: 'endDate',
        },
        {
          label: 'Imputations',
          key: 'imputations',
        },
      ],
    }
  },
  computed: {
    importErrors() {
      return Object.keys(this.notImported).length > 0
    }
  },
  methods: {
    importSheets() {
      this.importSuccess = false
      this.notImported = {}
      const importData = this.importData
      .map((row, idx) => {
        row.index = idx
        return row
      }).filter((r) => r.name || r.project || r.sheetTemplate || r.owner || r.orderNumber)
      return axios.post(`/import/sheets`, importData).then((response) => {
        if (response.data.invalid.length === 0 && Object.keys(response.data.error).length === 0) {
          this.importSuccess = true
        }

        else {
          this.notImported = response.data.error
        }
      });
    },
    showErrors(errors)
    {
      this.hasError = errors.length > 0
      this.lineErrors = errors;
    }
  }
}
</script>
<style>
.error-message {
  margin-top: 1.5rem;
  padding: 10px 2em;
}
</style>
